import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMaxYMid meet" viewBox="0 0 408 459">
    <path
      id="logoPath"
      fill="currentColor"
      d="M201.5 11.5a55625.191 55625.191 0 0 1 189.5 109c.667 73.333.667 146.667 0 220a52573.802 52573.802 0 0 0-188.5 109A52565.049 52565.049 0 0 0 14 340.5c-.667-73.333-.667-146.667 0-220a17059.83 17059.83 0 0 0 187.5-109Z"
    />
    <path
      fill="#0a192f"
      d="M201.5 32.5a14851.368 14851.368 0 0 1 172.5 99c.667 66 .667 132 0 198a43608.51 43608.51 0 0 1-171.5 99 43608.51 43608.51 0 0 1-171.5-99c-.667-66-.667-132 0-198a40840.58 40840.58 0 0 0 170.5-99Z"
    />
    <path
      fill="currentColor"
      d="M267.5 327.5c-8.818.993-17.818 1.327-27 1a4452.551 4452.551 0 0 1-39.5-80 415.804 415.804 0 0 0-40.5-1 1640.966 1640.966 0 0 0-1 81h-23v-197c24.002-.167 48.002 0 72 .5 40.837 4.163 60.004 26.663 57.5 67.5-4.565 23.064-18.065 38.064-40.5 45a4841.239 4841.239 0 0 1 42 83Z"
    />
    <path
      fill="#0a192f"
      d="M160.5 152.5c16.67-.167 33.337 0 50 .5 20.985 3.15 31.318 15.316 31 36.5-.356 24.356-12.69 37.356-37 39h-44v-76Z"
    />
  </svg>
);

export default IconLogo;
